import React from 'react'
import ArticleListPage from '../components/page/article/articleListPage'

const Articles = () => {
    return (
        <>
            <ArticleListPage />
        </>
    )
}

export default Articles
